import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138')
];

export const server_loads = [0,10,9];

export const dictionary = {
		"/(app)": [89,[10],[11]],
		"/(app)/_searches/[slug]": [96,[10],[11]],
		"/(rnd)/_table-legacy": [43,[2,4]],
		"/(rnd)/_table-legacy/settings": [44,[2,4,5]],
		"/(rnd)/_table-legacy/settings/general": [45,[2,4,5]],
		"/(rnd)/_table-legacy/settings/integrations": [46,[2,4,5]],
		"/(rnd)/_table-legacy/[table_id]": [47,[2,4]],
		"/(static)/about": [49,[6],[7]],
		"/(static)/agency": [48,[6],[7]],
		"/(static)/api": [62,[6],[7]],
		"/(static)/api/company": [64,[6],[7]],
		"/(static)/api/person": [65,[6],[7]],
		"/(static)/api/search": [63,[6],[7]],
		"/(static)/automations": [80,[6],[7]],
		"/(static)/(redirect)/billing/plans": [~81,[6],[7]],
		"/(static)/blog": [~76,[6],[7]],
		"/(static)/blog/[tag=blogTag]": [~78,[6],[7]],
		"/(static)/blog/[slug]": [~77,[6],[7]],
		"/(app)/campaigns": [121,[10,17],[11]],
		"/(app)/campaigns/[id]/edit": [~123,[10,17,18,19],[11]],
		"/(app)/campaigns/[id]/edit/1-icp": [127,[10,17,18,19],[11]],
		"/(app)/campaigns/[id]/edit/2-offer": [125,[10,17,18,19],[11]],
		"/(app)/campaigns/[id]/edit/3-outreach": [124,[10,17,18,19],[11]],
		"/(app)/campaigns/[id]/edit/4-settings": [126,[10,17,18,19],[11]],
		"/(app)/campaigns/[id]/edit/5-outbox": [128,[10,17,18,19],[11]],
		"/(app)/campaigns/[id]/view": [122,[10,17,18],[11]],
		"/(app)/chat-linkedin-profile/[profileUrl]": [129,[10],[11]],
		"/(static)/(redirect)/companies": [~82,[6],[7]],
		"/(static)/(redirect)/companies/[...path]": [~83,[6],[7]],
		"/(app)/dashboard": [~135,[10],[11]],
		"/(static)/demo": [61,[6],[7]],
		"/(static)/explore/companies": [~66,[6],[7]],
		"/(static)/explore/companies/p": [~71,[6],[7]],
		"/(static)/explore/companies/p/[page]": [~72,[6],[7]],
		"/(static)/explore/companies/[slug]": [67,[6,9],[7]],
		"/(static)/explore/companies/[slug]/people": [~68,[6,9],[7]],
		"/(static)/explore/companies/[slug]/people/page/[pageIndex]": [~69,[6,9],[7]],
		"/(static)/explore/companies/[slug]/people/[peopleSlug]": [~70,[6,9],[7]],
		"/(static)/explore/people": [~73,[6],[7]],
		"/(static)/explore/people/p": [~74,[6],[7]],
		"/(static)/explore/people/p/[page]": [~75,[6],[7]],
		"/(static)/(landings)/features/ai-pdf-processing": [50,[6,8],[7]],
		"/(static)/(landings)/features/ai-web-scraping": [51,[6,8],[7]],
		"/(app)/findai": [136,[10],[11]],
		"/(app)/forgot_password": [132,[10],[11]],
		"/(form)/form/[formId]": [22],
		"/(app)/free-chat-pdf": [114,[10],[11]],
		"/(app)/free-chat-pdf/[id]": [115,[10],[11]],
		"/(app)/invitation/accept": [118,[10],[11]],
		"/(app)/invitation/join": [117,[10],[11]],
		"/(static)/jobs": [79,[6],[7]],
		"/(app)/login": [138,[10],[11]],
		"/(app)/notebook": [137,[10],[11]],
		"/(app)/outbox": [99,[10],[11]],
		"/(app)/outbox/messages/[id]": [100,[10],[11]],
		"/(rnd)/outreach": [38,[2]],
		"/(rnd)/outreach/providers/connect": [40,[2]],
		"/(rnd)/outreach/providers/email/connect": [41,[2]],
		"/(rnd)/outreach/providers/new": [39,[2]],
		"/(app)/password_reset": [95,[10],[11]],
		"/(static)/(redirect)/people": [~85,[6],[7]],
		"/(app)/person-finder": [116,[10],[11]],
		"/(static)/plans": [~86,[6],[7]],
		"/(app)/platform": [106,[10],[11]],
		"/(app)/platform/workspaces/new": [107,[10],[11]],
		"/(app)/platform/workspaces/[id]": [108,[10],[11]],
		"/(app)/platform/workspaces/[id]/invitations/new": [109,[10],[11]],
		"/(app)/platform/workspaces/[id]/invitations/[token]/accept": [110,[10],[11]],
		"/(app)/platform/workspaces/[id]/[keys]": [111,[10],[11]],
		"/(static)/policies/privacy": [88,[6],[7]],
		"/(static)/policies/terms": [87,[6],[7]],
		"/(app)/posthog-err": [134,[10],[11]],
		"/(static)/(redirect)/pricing": [~84,[6],[7]],
		"/(app)/referral/[code]": [98,[10],[11]],
		"/(rnd)/rnd": [42,[2]],
		"/(rnd)/sandbox": [23,[2]],
		"/(rnd)/sandbox/amazon_agent": [24,[2]],
		"/(rnd)/sandbox/api_test": [26,[2]],
		"/(rnd)/sandbox/baby_sheet": [~34,[2,3]],
		"/(rnd)/sandbox/baby_sheet/[prompt_slug]": [35,[2,3]],
		"/(rnd)/sandbox/csv_download": [33,[2]],
		"/(rnd)/sandbox/dashboard": [37,[2]],
		"/(rnd)/sandbox/email_search": [30,[2]],
		"/(rnd)/sandbox/enrichment": [32,[2]],
		"/(rnd)/sandbox/keywords": [25,[2]],
		"/(rnd)/sandbox/rewrite_query": [29,[2]],
		"/(rnd)/sandbox/search-viewer": [28,[2]],
		"/(rnd)/sandbox/searchv2_5": [36,[2]],
		"/(rnd)/sandbox/search": [31,[2]],
		"/(rnd)/sandbox/title": [27,[2]],
		"/(app)/searches-dev": [130,[10,20],[11]],
		"/(app)/searches-dev/[slug]": [131,[10,20],[11]],
		"/(app)/search": [119,[10,16],[11]],
		"/(app)/search/[slug]": [120,[10,16],[11]],
		"/(app)/settings": [90,[10,12],[11]],
		"/(app)/settings/account": [94,[10,12],[11]],
		"/(app)/settings/billing": [92,[10,12],[11]],
		"/(app)/settings/provider": [93,[10,12],[11]],
		"/(app)/settings/teams": [91,[10,12],[11]],
		"/(app)/sign_up": [97,[10],[11]],
		"/(app)/table": [101,[10,13],[11]],
		"/(app)/table/settings": [102,[10,13,14],[11]],
		"/(app)/table/settings/general": [103,[10,13,14],[11]],
		"/(app)/table/settings/integrations": [104,[10,13,14],[11]],
		"/(app)/table/[table_id]": [105,[10,13],[11]],
		"/(static)/(landings)/templates": [~59,[6,8],[7]],
		"/(app)/templates/new": [133,[10,21],[11]],
		"/(static)/(landings)/templates/[label]": [~60,[6,8],[7]],
		"/(static)/(landings)/use-cases": [53,[6,8],[7]],
		"/(static)/(landings)/use-cases/__ai-ecommerce-automation": [57,[6,8],[7]],
		"/(static)/(landings)/use-cases/__ai-lead-search": [58,[6,8],[7]],
		"/(static)/(landings)/use-cases/ai-data-cleaning": [56,[6,8],[7]],
		"/(static)/(landings)/use-cases/ai-ecommerce-automation": [55,[6,8],[7]],
		"/(static)/(landings)/use-cases/recruiting-ai-resume-screening": [54,[6,8],[7]],
		"/(app)/users/[id]/set_password/[token]": [113,[10],[11]],
		"/(static)/(landings)/visual-gen": [52,[6,8],[7]],
		"/(app)/[...path]": [112,[10],[11,15]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';